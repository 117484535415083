import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} article />
            <Header />
            <BackgroundImage className="layout title__48 banner__background" fluid={entry.frontmatter.banner.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.banner.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="body flex flex__space-around">
                            <div className="block__60 text">
                                <h1>{ entry.frontmatter.heading }</h1>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
                meta_description
                background {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                banner {
                    heading
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1024) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`